import "./SideBar.scss";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../App";

function SideBar() {
  const [appContext] = useContext(AppContext);

  return (
    <section id="sidebar">
      <div className="inner">
        <div className="logo">
          <img src="/logo.svg" alt="PartnerCast" />
        </div>
        <nav>
          <div className="heading">Content Catalog</div>
          <ul>
            <li>
              <div className="nav-link-container">
                <NavLink end to="/" className="nav-link">
                  Welcome
                </NavLink>
              </div>
            </li>
            {appContext.menu.showWorkshops &&
              <li>
                <div className="nav-link-container">
                  <NavLink
                    to="workshops"
                    className="nav-link"
                  >
                    Workshops
                  </NavLink>
                </div>
              </li>
            }
            <li>
              <div className="nav-link-container">
                <NavLink
                  to="partnerreadiness"
                  className="nav-link"
                >
                  Partner Readiness
                </NavLink>
              </div>
            </li>
            <li>
              <div className="nav-link-container">
                <NavLink
                  to="event"
                  className="nav-link"
                >
                  Upcoming Events
                </NavLink>
              </div>
            </li>
          </ul>
          <div className="heading">On-Demand Content</div>
          <ul>
            <li>
              <div className="nav-link-container">
                <NavLink
                  to="odbusiness"
                  className="nav-link"
                >
                  Business Track
                </NavLink>
              </div>
            </li>
            <li>
              <div className="nav-link-container">
                <NavLink
                  to="odtechnical"
                  className="nav-link"
                >
                  Technical Track
                </NavLink>
              </div>
            </li>
          </ul>

        </nav>
      </div>
    </section>
  );
}

export default SideBar;
