import "./App.scss";
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer";
import HtmlDocument from "./components/HtmlDocument";
import Event from "./components/Event";
import PartnerReadiness from "./components/PartnerReadiness";
import Workshops from "./components/Workshops";
import usePageTracking from "./components/usePageTracking";

const defaultAppContext = {
  menu: {
    showWorkshops: true,
    showUpcomingEvents: true,
  },
  data: {
    workshops: []
  }
}
export const AppContext = React.createContext();

function App() {
  const [appContext, setAppContext] = useState(defaultAppContext)
  const [error, setError] = useState(null);

  usePageTracking();

  useEffect(() => {
    document.body.classList.remove("is-preload");
  }, []);

  useEffect(() => {
    fetch("/content/workshops.json")
      .then((res) => res.json(), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(
        (result) => {
          const workshops = result.data.Workshops.filter(function (x) {
            return !x.hidden;
          })
          const newContext = {
            ...appContext,
            menu: {
              ...appContext.menu,
              showWorkshops: workshops.length > 0
            },
            data: {
              workshops
            }
          }

          setAppContext(newContext);
        },
        (error) => {
          setError(error);
        }
      );
  }, [setAppContext]);

  return (
    <div>
      <AppContext.Provider value={[appContext, setAppContext]}>

        <ScrollToTop />
        <SideBar />
        <main role="main">
          <div id="content" className="container">
            <Routes>
              <Route
                path="/"
                element={<HtmlDocument location="/content/welcome.html" />}
              />
              <Route
                path="/odbusiness"
                element={<HtmlDocument location="/content/odbusiness.html" />}
              />
              <Route
                path="/odtechnical"
                element={<HtmlDocument location="/content/odtechnical.html" />}
              />
              <Route
                path="/event"
                element={
                  <Event
                    location-intro="/content/event.html"
                    location-data-1="/content/event.json"
                    location-data-2="/content/event-api.json"
                  />
                }
              />
              <Route
                path="/partnerreadiness"
                element={
                  <PartnerReadiness
                    location-intro="/content/partnerreadiness.html"
                    location-data="/content/partnerreadiness.json"
                  />
                }
                          />
              {!error && <Route
                path="/workshops"
                element={
                  <Workshops
                    location-intro="/content/workshops.html"
                  />
                }
              />
              }
            </Routes>
          </div>
          <Footer />
        </main>
      </AppContext.Provider>
    </div>
  );
}

export default App;
